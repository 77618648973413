import { template as template_f9966ba05e294151b72377111b8bffe8 } from "@ember/template-compiler";
const FKText = template_f9966ba05e294151b72377111b8bffe8(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
