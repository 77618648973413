import { template as template_44d3809adebf49c49ce31336b34d5461 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_44d3809adebf49c49ce31336b34d5461(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
