import { template as template_36341ca8a7044da2bb42c389b6b46f7a } from "@ember/template-compiler";
const FKControlMenuContainer = template_36341ca8a7044da2bb42c389b6b46f7a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
